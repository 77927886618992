import React from 'react'
import ErrorPage from "./404"
import Layout from '../components/Layout'

const quote = () => {
  return (
    <Layout>
        <ErrorPage/>
    </Layout>
  )
}

export default quote